import { useMemo } from 'react';
// eslint-disable-next-line no-restricted-imports
import { useRouter } from 'next/router';
import { useConstants } from '@context/ConstantContext';
import useIsOnClient from '@hooks/useIsOnClient';
import { parseQueryStringToObject } from '@utils/queryUtils';
import { QueryString, toQueryParams } from '@utils/routerUtils';
import { Route } from '@web/types/enums';

const usePartnerRouter = () => {
  const { partner } = useConstants();

  const {
    asPath,
    back,
    beforePopState,
    events,
    isReady,
    locale,
    pathname: currentPathname,
    prefetch,
    push,
    query,
    reload,
    replace,
    route,
  } = useRouter();

  const isClient = useIsOnClient();
  const currentQuery = useMemo(() => {
    const { partner, ...rest } = query;

    return Object.keys(rest).length === 0 &&
      typeof window !== 'undefined' &&
      isClient
      ? parseQueryStringToObject(window.location.search)
      : rest;
  }, [query, isClient]);

  return {
    push: ({
      keepPreviousQuery = true,
      pathname,
      query = {},
      shallow,
    }: {
      keepPreviousQuery?: boolean;
      pathname?: string;
      query?: QueryString;
      shallow?: boolean;
    }) => {
      const newPath = (pathname || currentPathname).replace('/[partner]', '');
      const newQuery = {
        ...(keepPreviousQuery ? currentQuery : {}),
        ...query,
        ...(shallow ? { partner } : {}),
      };

      return push(
        {
          pathname: `/[partner]${newPath}`,
          query: newQuery,
        },
        `${newPath}${toQueryParams(newQuery)}`,
        {
          shallow,
          isSsg: true,
        },
      );
    },
    replace: ({
      keepPreviousQuery = true,
      pathname,
      query,
      shallow,
    }: {
      keepPreviousQuery?: boolean;
      pathname?: string;
      query?: QueryString;
      shallow: boolean;
    }) => {
      const newPath = (pathname || currentPathname).replace('/[partner]', '');
      const newQuery = {
        ...(keepPreviousQuery ? currentQuery : {}),
        ...query,
        ...(shallow ? { partner } : {}),
      };

      replace(
        {
          pathname: `/[partner]${newPath}`,
          query: newQuery,
        },
        `${newPath}${toQueryParams(newQuery)}`,
        {
          shallow,
          isSsg: true,
        },
      );
    },
    getPartnerHref: ({
      keepPreviousQuery = true,
      pathname,
      query = {},
    }: {
      keepPreviousQuery?: boolean;
      pathname?: string;
      query?: QueryString;
    }) => {
      const path = pathname || currentPathname;
      const newQuery = {
        ...(keepPreviousQuery ? currentQuery : {}),
        ...query,
      };

      return `${path}${toQueryParams(newQuery)}`;
    },
    asPath: asPath.replace('/[partner]', ''),
    query: currentQuery,
    events,
    beforePopState,
    route: (route === '/[partner]'
      ? '/'
      : route.replace('/[partner]', '')) as Route,
    back,
    reload,
    isReady,
    prefetch,
    locale,
  };
};

export default usePartnerRouter;
